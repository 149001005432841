// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

$(document).ready(function () {
  ///////////////// fixed menu on scroll for desktop
  if ($(window).width() > 992) {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 10) {
        $("#navbar-top").addClass("navbar--scrolling");
      } else {
        $("#navbar-top").removeClass("navbar--scrolling");
      }
    });
  } // end if

  //Toont popup na 5 seconden
  setTimeout(show_popup, 5000);

  $(".close").on("click", function () {
    close_popup();
  });

  /*
   *	RICH-CONTENT
   */

  // Lightbox
  $(".lightbox").fancybox({
    overlayOpacity: 0.8,
    overlayColor: "black",
    padding: 0,
    titleShow: true,
  });
  $(".lightbox-media").fancybox({
    overlayOpacity: 0.8,
    overlayColor: "black",
    padding: 0,
    titleShow: true,
    helpers: {
      media: {},
    },
  });

  // Youtube/Vimeo thumbs
  $(".rc-video__thumb").not(".lightbox-media").click(startVideo);
  $(".rc-video__overlay").not(".lightbox-media").click(startVideo);

  function startVideo(e) {
    var block = $(e.currentTarget);

    var iframe = $("iframe", block.parent());
    var src = iframe.attr("src");

    if (src.indexOf("autoplay=0") > -1) {
      iframe.attr("src", src.replace("autoplay=0", "autoplay=1"));
    } else {
      if (src.indexOf("?") > -1) {
        iframe.attr("src", src + "&autoplay=1");
      } else {
        iframe.attr("src", src + "?autoplay=1");
      }
    }

    $(".rc-video__overlay", block.parent()).fadeOut(150);
    $(".rc-video__thumb", block.parent()).fadeOut(150);
  }

  // Contentblocks slider
  $(".rc-article-slider").slick({
    adaptiveHeight: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
  });

  // Quote slider
  $(".rc-quote-slider__slick").slick({
    adaptiveHeight: true,
    prevArrow:
      '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
    nextArrow:
      '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
  });

  $(".go-down").on("click", function () {
    $("html, body").animate(
      {
        scrollTop:
          $("#suitenings-info").offset().top - $(".header").outerHeight(),
      },
      1000
    );
  });

  // File upload
  bsCustomFileInput.init();
});

$(window).on("load", function () {
  // Image slider (options in data-slick)
  $(".rc-image-slider__slick").slick();
});

function show_popup() {
  $(".pop-up-bottom").animate({
    right: "0px",
  });
}

function close_popup() {
  $.get("/popup/close");
  $(".pop-up-bottom").animate({
    right: "-300px",
  });
}

// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response) {
  captcha_validated = true;
  $(".rc-form form").submit();
}

function validateForm(event) {
  event.preventDefault();
  var form = this;

  var validator = $(form).data("bs.validator");
  validator.validate();

  if (captcha_validated && !validator.hasErrors()) {
    form.submit();
  } else if (!captcha_validated) {
    grecaptcha.reset();
    grecaptcha.execute();
  }
}
